<template>
  <main class="main--not-logged">
    <div class="banner">
      <img
        :src="require('../assets/img/architecture-g19c3e4465_1920_low.jpg')"
        class="banner__img"
      />
    </div>
    <div class="main-content">
      <shout-box
        :data="shoutBoxData"
      >
        <template slot="custom-content">
          <div class="shoutbox__custom-content">
            <form-account-request no-privacy />
          </div>
        </template>
      </shout-box>
    </div>
    <footer-component />
  </main>
</template>

<script>
const FormAccountRequest = () => import('@/components/forms/FormAccountRequest.vue');
const ShoutBox = () => import('@/components/ShoutBox.vue');
const FooterComponent = () => import('@/components/layout/FooterComponent.vue');

export default {
  name: 'NewUserRegister',
  components: {
    FormAccountRequest,
    ShoutBox,
    FooterComponent,
  },
  data() {
    return {
      shoutBoxData: {
        title: '<span class="text-warning">MF Solvency</span> è il nuovo Portale per rating, visure e bilanci.',
        subtitle: 'Inserisci la tua email per essere informato non appena il Portale sarà operativo.',
        // title: '<span class="text-warning">Registrati</span> per utilizzare tutti i servizi di MF Solvency',
        // subtitle: 'Certifica la tua affidabilità nella gestione dei rapporti commerciali'
        //   + ' e nella gestione dei pagamenti',
      },
    };
  },
  metaInfo() {
    return {
      title: 'Registrazione utente - MF Solvency',
      meta: [
        { property: 'og:title', content: 'Registrazione utente - MF Solvency' },
        { property: 'og:site_name', content: 'MF Solvency' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
  mounted() {
    if (this.$store.getters['auth/isLogged']) {
      this.$router.replace('/dashboard-utente');
      return;
    }
    // console.debug('Register setpreloader', false);
    this.$store.dispatch('setPreloader', false);
  },
};
</script>

import Vue from 'vue';
import VueRouter from 'vue-router';
import ValidateToken from '@/views/ValidateToken.vue';
import Home from '@/views/Home.vue';
import UserDashboard from '@/views/UserDashboard.vue';
import Login from '@/views/Login.vue';
import UserAnalysisRequest from '@/views/UserAnalysisRequest.vue';
import UserAnalysisHistory from '@/views/UserAnalysisHistory.vue';
import UserAnalysisDetail from '@/views/UserAnalysisDetail.vue';
import UserData from '@/views/UserData.vue';
import RecoverPassword from '@/views/RecoverPassword.vue';
import UserWallet from '@/views/UserWallet.vue';
import UserWalletTopup from '@/views/UserWalletTopup.vue';
import UserViewProfile from '@/views/UserViewProfile.vue';
import UserInvoiceDetail from '@/views/UserInvoiceDetail.vue';
import UserProductList from '@/views/UserProductList.vue';
import UserProfileSearch from '@/views/UserProfileSearch.vue';
import ErrorPage from '@/views/ErrorPage.vue';
import UploadRequest from '@/views/UploadRequest.vue';
import NewUserRegister from '@/views/NewUserRegister.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/registrati',
    name: 'Register',
    component: NewUserRegister,
  },
  {
    path: '/accedi',
    name: 'Login',
    component: Login,
  },
  {
    path: '/dashboard-utente',
    name: 'Dashboard Utente',
    component: UserDashboard,
  },
  {
    path: '/validate-user/:token',
    name: 'ValidateToken',
    component: ValidateToken,
  },
  {
    path: '/request-analysis',
    name: 'Richiedi analisi',
    component: UserAnalysisRequest,
  },
  {
    path: '/user-analysis-history',
    name: 'Richiest',
    component: UserAnalysisHistory,
  },
  {
    path: '/user-analysis-detail/:requestName',
    name: 'Dettaglio Segnalazione',
    component: UserAnalysisDetail,
  },
  {
    path: '/user-data/:activeTab',
    name: 'AnagraficaTab',
    component: UserData,
  },
  {
    path: '/user-data',
    name: 'Anagrafica',
    component: UserData,
  },
  {
    path: '/password-recovery',
    name: 'Recupero Password',
    component: RecoverPassword,
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: UserWallet,
  },
  {
    path: '/wallet-topup',
    name: 'WalletTopup',
    component: UserWalletTopup,
  },
  // {
  //   path: '/cr-invoice',
  //   name: 'CR Invoice',
  //   component: UserCRInvoice,
  // },
  {
    path: '/user-invoice-detail/:requestName',
    name: 'Dettaglio Fattura',
    component: UserInvoiceDetail,
  },
  // {
  //   path: '/portale-delle-imprese',
  //   name: 'Portale delle Imprese',
  //   component: UserClubCompany,
  // },
  {
    path: '/mf-solvency/:entityType/:entityTax',
    name: 'MF Solvency Profilo',
    component: UserViewProfile,
  },
  {
    path: '/company-search/:entityType/:entityTax',
    name: 'Company Search Profilo',
    component: UserViewProfile,
  },
  {
    path: '/my-profile',
    name: 'La mia azienda',
    component: UserViewProfile,
  },
  {
    path: '/product-list',
    name: 'Visure e Report',
    component: UserProductList,
  },
  {
    path: '/product-visure',
    name: 'Visure',
    component: UserProductList,
    props: {
      context: 'visura',
    },
  },
  {
    path: '/product-bilanci',
    name: 'Bilanci',
    component: UserProductList,
    props: {
      context: 'bilancio',
    },
  },
  {
    path: '/product-report',
    name: 'Report',
    component: UserProductList,
    props: {
      context: 'report',
    },
  },
  {
    path: '/profile-search',
    name: 'Ricerca Profilo',
    component: UserProfileSearch,
    props: {
      context: 'search',
    },
  },
  {
    path: '/click-search',
    name: 'Click Report',
    component: UserProfileSearch,
    props: {
      context: 'click',
    },
  },
  {
    path: '/document-invoice-upload/:requestName',
    name: 'Carica Documenti',
    component: UploadRequest,
  },
  {
    path: '*',
    component: ErrorPage,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
